import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../index.css';

function ORCiD_Login() {
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [nonce, setNonce] = useState('');

    // Generate nonce and handle widget initialization
    useEffect(() => {
        try {
            const generateNonce = () => {
                const array = new Uint32Array(4);
                window.crypto.getRandomValues(array);
                return Array.from(array, dec => dec.toString(16).padStart(8, '0')).join('');
            };

            const newNonce = generateNonce();
            sessionStorage.setItem('orcidNonce', newNonce);
            setNonce(newNonce);

            // Initialize widget after nonce is set
            const widget = document.getElementById('orcidWidget');
            if (widget) {
                widget.setAttribute('data-nonce', newNonce);
            }
        } catch (err) {
            console.error('Error initializing ORCID widget:', err);
            setError('Error initializing ORCID authentication');
        }
    }, []);

    // Process response from ORCID
    useEffect(() => {
        try {
            const fragment = new URLSearchParams(window.location.hash.substring(1));
            const idToken = fragment.get('id_token');

            if (idToken) {
                const payload = JSON.parse(atob(idToken.split('.')[1]));
                const storedNonce = sessionStorage.getItem('orcidNonce');

                if (payload.nonce === storedNonce) {
                    const orcid = payload.sub;
                    const currentInfo = sessionStorage.getItem('userInfo');
                    const storedInfo = currentInfo ? JSON.parse(currentInfo) : {};
                    const updatedInfo = { ...storedInfo, orcidId: orcid };
                    sessionStorage.setItem('userInfo', JSON.stringify(updatedInfo));
                    setUserInfo(updatedInfo);
                    navigate('/Department');
                } else {
                    console.error('Invalid nonce in token');
                    navigate('/Login_Failure');
                }
            }
        } catch (err) {
            console.error('Error processing ORCID response:', err);
            navigate('/Login_Failure');
        }
    }, [navigate]);

    // Fetch user info
    useEffect(() => {
        fetch('/api/user', {
            credentials: 'include'
        })
            .then(res => {
                if (!res.ok) throw new Error('Not authenticated');
                return res.json();
            })
            .then(data => {
                setUserInfo(data);
            })
            .catch(err => {
                console.error('Error fetching user info:', err);
                navigate('/Login_Failure');
            });
    }, [navigate]);

    if (error) {
        return (
            <div className="min-h-screen bg-[#003865] flex items-center justify-center p-4">
                <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
                    <div className="text-center text-red-600">
                        <p>{error}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#003865] flex items-center justify-center p-4">
            <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
                <div className="text-center mb-8">
                    <h1 className="text-2xl font-bold text-[#003865] mb-4">
                        Connect ORCID iD
                    </h1>
                    {userInfo && (
                        <div className="mb-4 text-gray-700">
                            <p>Welcome, {userInfo.fullName}</p>
                        </div>
                    )}
                    <p className="text-gray-600">
                        Step 2 of 3: Connect your ORCID iD
                    </p>
                </div>

                <div id="orcidWidget" className="text-center"
                     data-size="lg">
                </div>
            </div>
        </div>
    );
}

export default ORCiD_Login;