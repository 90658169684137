// import './App.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ORCiD_Login from './component/ORCiD_Login';
// import UR_Login from './component/UR_Login';
// import Home from './component/Home'
// import Department from "./component/Department";

// function App() {
//     return (
//         <Router>
//             <div className="App">
//                 <Routes>
//                     <Route path="/ORCiD_Login" element={<ORCiD_Login />} />
//                     <Route path="/UR_Login" element={<UR_Login />} />
//                     <Route path="/Department" element={<Department />}/>
//                     <Route path="/" element={<Home />}/>
//                 </Routes>
//             </div>
//         </Router>
//     );
// }

// export default App;

import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ORCiD_Login from './component/ORCiD_Login';
import UR_Login from './component/UR_Login';
import Home from './component/Home'
import Department from "./component/Department";
import Login_Failure from "./component/Login_Failure";
import Callback from "./component/Callback";
import Success from "./component/Success";  // Add this line

function App() {
    
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/ORCiD_Login" element={<ORCiD_Login/>} />
                    <Route path="/UR_Login" element={<UR_Login />} />
                    <Route path="/Department" element={<Department />}/>
                    <Route path="/Login_Failure" element={<Login_Failure />}/>
                    <Route path="/success" element={<Success />} />
                    <Route path="/api/login/callback" element={<Callback />} />
                    <Route path="/" element={<Home />}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
