import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

const Success = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-[#003865] flex items-center justify-center p-4">
            <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8 text-center">
                <div className="mb-6">
                    <CheckCircle
                        size={64}
                        className="mx-auto text-[#F2C700]"
                    />
                </div>

                <h1 className="text-2xl font-bold text-[#003865] mb-4">
                    Connection Successful!
                </h1>

                <div className="mb-8 text-gray-600">
                    <p>Your ORCID iD has been successfully connected to your University of Rochester profile.</p>
                    <p className="mt-2">Thank you for helping to maintain accurate research attribution records.</p>
                </div>

                <button
                    onClick={() => navigate('/')}
                    className="bg-[#F2C700] text-[#003865] px-6 py-3 rounded-lg font-semibold
                             hover:bg-opacity-90 transition-opacity duration-200"
                >
                    Return Home
                </button>

                <div className="mt-8 text-sm text-gray-500">
                    <p>Need assistance? Contact Channing Norton at cnorton9@library.rochester.edu</p>
                </div>
            </div>
        </div>
    );
};

export default Success;