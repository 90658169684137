import React from 'react';

function UR_Login() {
    const styles = {
        wrapper: {
            backgroundColor: '#003865',
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        login: {
            color: '#F2C700',
            backgroundColor: '#003865',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
        },
        button: {
            backgroundColor: '#F2C700',
            color: '#003865',
            border: 'none',
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
        }
    };
    const handleLogin = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/api/login`;
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.login}>
                <h1 style={styles.login}>University of Rochester Login</h1>
                <p>Step 1 of 3: Authenticate with your University credentials</p>
                <button style={styles.button} onClick={handleLogin}>
                    Log in with UR Credentials
                </button>
            </div>
        </div>
    );
}

export default UR_Login;