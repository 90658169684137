import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Department = () => {
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [otherDepartment, setOtherDepartment] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [oauthData, setOauthData] = useState(null);
    const codeExchangeRef = useRef(false);
    const navigate = useNavigate();

    // Enhanced debug logging
    const logOAuthDebug = (message, data) => {
        console.log('=== ORCID OAuth Debug ===');
        console.log('Message:', message);
        console.log('Full URL:', window.location.href);
        console.log('Hash:', window.location.hash);
        console.log('Search:', window.location.search);
        console.log('Data:', data);
        console.log('========================');
    };

    // Fetch departments
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await fetch('/api/departments', {
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to fetch departments');
                const data = await response.json();
                setDepartments([...new Set([...data, 'Other'])].sort());
            } catch (err) {
                console.error('Error fetching departments:', err);
                setError('Failed to load departments');
            }
        };

        fetchDepartments();
    }, []);

    // Process OAuth data and fetch user info
    useEffect(() => {
        const processOAuth = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                if (code) {
                    // Check both ref and session storage to prevent duplicate exchanges
                    const exchanged = sessionStorage.getItem(`code_exchanged_${code}`);
                    if (exchanged || codeExchangeRef.current) {
                        logOAuthDebug('Code already exchanged', { code });
                        return;
                    }

                    codeExchangeRef.current = true;
                    sessionStorage.setItem(`code_exchanged_${code}`, 'true');

                    logOAuthDebug('Processing authorization code', { code });

                    const tokenResponse = await fetch('/api/token', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ code })
                    });

                    const responseData = await tokenResponse.json();

                    if (!tokenResponse.ok) {
                        throw new Error(responseData.details || 'Token exchange failed');
                    }

                    logOAuthDebug('Received tokens', {
                        access_token: responseData.access_token ? 'Present' : 'Missing',
                        refresh_token: responseData.refresh_token ? 'Present' : 'Missing',
                        id_token: responseData.id_token ? 'Present' : 'Missing'
                    });

                    const oauthInfo = {
                        access_token: responseData.access_token,
                        refresh_token: responseData.refresh_token,
                        id_token: responseData.id_token,
                        scope: responseData.scope
                    };

                    if (oauthInfo.access_token && oauthInfo.refresh_token) {
                        oauthInfo.formatted_tokens = `${oauthInfo.access_token},${oauthInfo.refresh_token}`;
                    }

                    // Try to decode the ID token if present
                    if (oauthInfo.id_token) {
                        try {
                            const [header, payload, signature] = oauthInfo.id_token.split('.');
                            oauthInfo.decoded_payload = JSON.parse(atob(payload));
                        } catch (e) {
                            console.error('Error decoding ID token:', e);
                        }
                    }

                    setOauthData(oauthInfo);

                    // Remove code from URL to prevent reuse
                    window.history.replaceState({}, document.title, '/Department');
                }

                // Fetch user info regardless of OAuth status
                const userResponse = await fetch('/api/user', {
                    credentials: 'include'
                });

                if (!userResponse.ok) {
                    throw new Error('Not authenticated');
                }

                const userData = await userResponse.json();
                setUserInfo(userData);
                setIsLoading(false);

            } catch (err) {
                logOAuthDebug('Error in OAuth/user data processing', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        processOAuth();
    }, []); // Empty dependency array since we use ref for tracking

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!userInfo?.netId || !oauthData?.decoded_payload?.sub) {
            setError('Missing required user information');
            return;
        }

        try {
            // Check if user exists
            const checkResponse = await fetch(`/api/check-user?netId=${userInfo.netId}&orcidId=${oauthData.decoded_payload.sub}`);
            const existingUser = await checkResponse.json();

            if (existingUser.netId && existingUser.orcidId) {
                setError('This user already exists. Please contact Channing Norton (cnorton9@ur.rochester.edu) if you believe this is a mistake.');
                return;
            }

            if (existingUser.netId || existingUser.orcidId) {
                setError('Please contact Channing Norton (cnorton9@ur.rochester.edu) for assistance.');
                return;
            }

            // Create new user with actual tokens
            const department = selectedDepartment === 'Other' ? otherDepartment : selectedDepartment;
            const [accessToken, refreshToken] = oauthData.formatted_tokens.split(',');

            const response = await fetch('/api/create-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    netId: userInfo.netId,
                    urid: userInfo.urid,
                    email: userInfo.email,
                    fullName: userInfo.fullName,
                    department,
                    orcidId: oauthData.decoded_payload.sub,
                    tokenType: 'Bearer',
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                    expiresIn: 631138518,
                    scopes: oauthData.scope || 'openid'
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create user');
            }

            navigate('/success');
        } catch (err) {
            console.error('Error in submission:', err);
            setError('An error occurred during submission. Please try again.');
        }
    };

    if (isLoading) {
        return (
            <div>
                <div>
                    <p>Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <div>
                    <h1>Error</h1>
                    <p>{error}</p>
                    <button onClick={() => navigate('/UR_Login')}>
                        Return to Login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div>
                <h1>Academic Department</h1>

                {/* Debug Information Display */}
                {oauthData && (
                    <div>
                        <h3>OAuth Information</h3>
                        <div>
                            {oauthData.error ? (
                                <>
                                    <p><strong>Error:</strong> {oauthData.error}</p>
                                    <p><strong>Description:</strong> {oauthData.error_description}</p>
                                </>
                            ) : (
                                <>
                                    <p><strong>Tokens:</strong> {oauthData.formatted_tokens}</p>
                                    {oauthData.decoded_payload && (
                                        <p><strong>ORCID:</strong> {oauthData.decoded_payload.sub || 'Not provided'}</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}

                {/* User Info Display */}
                {userInfo && (
                    <div>
                        <p>NetID: {userInfo.netId}</p>
                        <p>Full Name: {userInfo.fullName}</p>
                        <p>Email: {userInfo.email}</p>
                    </div>
                )}

                {/* Department Selection Form */}
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="department">
                            Select Your Department
                        </label>
                        <select
                            id="department"
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                            required
                        >
                            <option value="">Select a department...</option>
                            {departments.map((dept, index) => (
                                <option key={index} value={dept}>{dept}</option>
                            ))}
                        </select>
                    </div>

                    {selectedDepartment === 'Other' && (
                        <div>
                            <label htmlFor="other">
                                Other Department
                            </label>
                            <input
                                type="text"
                                id="other"
                                value={otherDepartment}
                                onChange={(e) => setOtherDepartment(e.target.value)}
                                placeholder="Enter your department"
                                required
                            />
                        </div>
                    )}

                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#003865',
        fontFamily: "'Arial', sans-serif",
    },
    formContainer: {
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '400px',
    },
    title: {
        color: '#003865',
        fontSize: '1.5rem',
        marginBottom: '1.5rem',
        textAlign: 'center',
    },
    userInfo: {
        backgroundColor: '#f5f5f5',
        padding: '1rem',
        borderRadius: '4px',
        marginBottom: '1.5rem',
        fontSize: '0.875rem',
        color: '#666',
        wordBreak: 'break-all'
    },
    oauthTitle: {
        fontWeight: 'bold',
        marginTop: '1rem',
        color: '#003865',
    },
    inputGroup: {
        marginBottom: '1rem',
    },
    label: {
        display: 'block',
        marginBottom: '0.5rem',
        color: '#003865',
        fontSize: '0.875rem',
    },
    input: {
        width: '100%',
        padding: '0.5rem',
        border: '1px solid #003865',
        borderRadius: '4px',
        fontSize: '1rem',
    },
    button: {
        width: '100%',
        padding: '0.75rem',
        backgroundColor: '#F2C700',
        color: '#003865',
        border: 'none',
        borderRadius: '4px',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    loadingText: {
        textAlign: 'center',
        color: '#003865',
    },
    errorText: {
        color: '#dc2626',
        marginBottom: '1rem',
        textAlign: 'center',
    }
};


export default Department;
