import React from 'react';

const LoginFailure = () => {
    const navyBlue = '#003865';
    const gold = '#F2C700';

    // Custom warning triangle using SVG
    const WarningIcon = () => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke={gold}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/>
            <line x1="12" y1="9" x2="12" y2="13"/>
            <line x1="12" y1="17" x2="12.01" y2="17"/>
        </svg>
    );

    return (
        <div style={{backgroundColor: navyBlue}} className="min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md p-8">
                <div className="bg-white rounded-lg p-4" style={{borderColor: gold, borderWidth: '2px'}}>
                    <div className="flex items-center gap-2">
                        <WarningIcon />
                        <h1 style={{color: navyBlue}} className="text-2xl font-bold">
                            Something went wrong
                        </h1>
                    </div>
                    <p style={{color: navyBlue}} className="mt-2">
                        We encountered an error during the login process. Please try again or contact support if the issue persists.
                    </p>
                </div>
                <div className="mt-4 text-center">
                    <button
                        onClick={() => window.location.href = '/'}
                        style={{backgroundColor: gold, color: navyBlue}}
                        className="px-6 py-2 rounded-md font-semibold hover:opacity-90 transition-opacity"
                    >
                        Return Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginFailure;