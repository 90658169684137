import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check authentication state
        fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            credentials: 'include'
        })
            .then(response => {
                if (response.ok) {
                    navigate('/Department'); // Redirect to department selection
                } else {
                    navigate('/Login_Failure');
                }
            })
            .catch(err => {
                console.error('Auth check failed:', err);
                navigate('/Login_Failure');
            });
    }, [navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#003865]">
            <div className="bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4 text-[#003865]">Processing Authentication</h2>
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#003865] mx-auto"></div>
            </div>
        </div>
    );
};

export default Callback;