import React from 'react';
import {Link} from 'react-router-dom';

const Home = () => {
    const styles = {
        container: {
            minHeight: '100vh',
            backgroundColor: '#003865', // Dark blue
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            marginBottom: '2rem',
        },
        title: {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#F2C700', // Yellow
        },
        main: {
            textAlign: 'center',
        },
        description: {
            color: 'white',
            fontSize: '1.25rem',
            marginBottom: '2rem',
        },
        button: {
            backgroundColor: '#F2C700', // Yellow
            color: '#003865', // Dark blue
            border: 'none',
            padding: '1rem 2rem',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            borderRadius: '9999px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        footer: {
            marginTop: '4rem',
            color: 'white',
            fontSize: '0.875rem',
        },
    };

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <h1 style={styles.title}>University of Rochester</h1>
            </header>

            <main style={styles.main}>
                <p style={styles.description}>
                    Welcome to the University of Rochester's research portal
                </p>
                <Link to={"/UR_Login"}>
                    <button
                        style={styles.button}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#FFE066'} // Lighter yellow on hover
                        onMouseOut={(e) => e.target.style.backgroundColor = '#F2C700'} // Back to original yellow
                    >Connect your ORCiD to the University of Rochester
                    </button>
                </Link>
            </main>

            <footer style={styles.footer}>
                &copy; {new Date().getFullYear()} University of Rochester. All rights reserved.
            </footer>
        </div>
    );
};

export default Home;